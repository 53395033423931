<template>
  <v-dialog
    v-model="dialog"
    transition="dialog-transition"
    max-width="1400"
    persistent
  >
  <v-card>
    <v-toolbar flat>
      <v-toolbar-title>
        <span class="headline"> Cortesias  </span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-icon large @click="handleClose()">mdi-close</v-icon>
    </v-toolbar>
    <v-divider />
    <v-row v-if="loading" class="justify-center height-body">
      <v-progress-circular
        indeterminate
        color="primary"
      ></v-progress-circular>
    </v-row>
    <v-col cols="12">
        <v-col col="12" class="mt-2">
            <v-col cols="12" class="d-flex justify-space-between">
              <h2> {{ eventName }} </h2>
              <v-btn outlined color="primary" @click="handleOpenForm()"> Nova Cortesia</v-btn>
            </v-col>
            <v-col cols="12">
                <v-data-table
                  :headers="headers"
                  :items="cortesias"
                  :key="newCounter"
                  :items-per-page="5"
                  class="elevation-1"
                >
                  <template v-slot:body="{ items }" ma-5>
                    <tbody>
                      <tr v-for="(item, index) in items" :key="index"> 
                        <td>{{ item.usuarioExternoNome }}</td>
                        <td>{{ item.categoriaDescricao }}</td>
                        <td>{{ item.dataCadastro | filtraDH }}</td>
                        <td>{{ item.comissarioNome ? item.comissarioNome : 'N/A' }}</td>
                        <td class="text-end">
                          <v-icon :color="item.isPago ? 'success' : ''"> mdi-ticket </v-icon>
                        </td>
                        <td class="text-end">
                          <v-btn 
                            icon
                            @click="handleEdit(item)"
                            :disabled="item.isPago || situacao == 4 || situacao == 3"
                          > 
                            <v-icon> mdi-pencil </v-icon>
                          </v-btn>
                          <v-btn 
                          :disabled="item.isPago || situacao == 4 || situacao == 3"
                            icon
                            @click="handleDelete(item)" 
                          > 
                            <v-icon color="primary" >
                              mdi-delete
                            </v-icon>
                          </v-btn>
                        </td>

                      </tr>
                    </tbody>
                  </template>
                </v-data-table>
            </v-col>
        </v-col>
        <v-row v-if="loadingCortesia" class="justify-center height-body">
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </v-row>
    </v-col>
  </v-card> 
    <v-dialog
      v-model="openForm"
      :key="counter"
      @click:outside="openForm = false"
      max-width="1000px"
      transition="dialog-transition"
    >
      <v-card>
          <v-toolbar flat>
            <v-toolbar-title>
              <span class="headline"> Nova Cortesia  </span>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-icon large @click="openForm = false">mdi-close</v-icon>
          </v-toolbar>
        <v-divider />
        <v-col cols='12' class="d-flex pt-0">
          <v-col cols='12' class="d-flex flex-wrap">
            <span class="text-body-1 font-weight-bold w-full"> {{ eventName }} </span>
            <span> Data do Evento: {{ date | filtrarExtenso }} </span>
          </v-col> 
          </v-col>
          <v-col cols='12'>
          <v-text-field 
            v-model="usuario"
            :rules="[rules.email]"
            :disabled="editing"
            label="Usuário"
          />
          <v-text-field 
            v-model="quantidade"
            :disabled="editing"
            label="Quantidade"
          />
          <v-select 
              v-model="categoria"
              :items="categorias"
              :disabled="editing"
              label="Categorias"
            />
<v-text-field
              v-if="comissario || comissarioNome"
              v-model="comissarioField"
              disabled
              :label="comissarioNome ? comissarioNome : comissario.nome + ' - ' + comissario.email"
            />
          
          </v-col>
          <v-col cols="12" class="d-flex justify-end">
          <v-btn color="btnCancelar" class="mr-2 text-capitalize" text @click="openForm = false">
            <span> Cancelar </span>
          </v-btn>
          <v-btn color="primary" :loading="loading" :disabled="editing" @click="salvar()">
            Salvar
          </v-btn>
        </v-col>
      </v-card>
    </v-dialog>
  
    <v-dialog
        v-model="dialogExcluirCortesia"
        @click:outside="dialogExcluirCortesia = false"
        max-width="350px"
        transition="dialog-transition"
        >
        <v-card>
            <v-card-title> Deseja excluir a cortesia? </v-card-title>
            <v-card-text> A ação é irreversível. </v-card-text>
            <v-card-actions class="justify-end">
            <v-btn small @click="dialogExcluirCortesia = false"> cancelar </v-btn>
            <v-btn small color="primary" @click="excluirCortesia()"> confirmar </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <v-dialog
        v-model="dialogCriarUsuario"
        @click:outside="dialogCriarUsuario = false"
        max-width="350px"
        transition="dialog-transition"
        >
        <v-card>
            <v-card-title> Criar Novo Usuário </v-card-title>
            <v-card-text> O usuario com o e-mail {{ usuario }} não foi encontrado deseja criar um novo? </v-card-text>
            <v-card-actions class="justify-end">
            <v-btn small @click="dialogCriarUsuario = false"> cancelar </v-btn>
            <v-btn small color="primary" @click="newUsuarioExterno()"> confirmar </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
      <v-dialog
        v-model="loader"
        hide-overlay
        persistent
        width="300"
        >
        <v-card
            color="primary"
            dark
        >
            <v-card-text>
            Aguarde
            <v-progress-linear
                indeterminate
                color="white"
                class="mb-0"
            ></v-progress-linear>
            </v-card-text>
        </v-card>
        </v-dialog>
  </v-dialog> 
</template>

<script>
import moment from "moment";
import { IngressoService, UsuarioExternoService, CortesiaService } from '@/services';

export default {
  name: "moduloCortesia",

  props: {
    id: {
        type: Number,
        default: null
    },

    eventName: {
      type: String,
      default: ''
    },

    situacao: {
      type: Number,
      default: 1
    },

    date: {
      type: Number,
      default: 0,
    },

    comissario: {
      type: Object,
    }
  },

  data() {
    return {
      counter: 0,
      panel: [],
      dialog: true,
      openForm: false,
      codigo: null,
      dialogExcluirCortesia: false,
      data: null,
      usuario: null,
      usuarioId: null,
      quantidade: null,
      editing: false,
      comissarioField: '',
      categoria: null,
      comissarioNome: null,
      cortesiaObj: {},
      categorias: [],
      headers: [
        {
          text: 'Usuário',
          align: 'start',
          sortable: false,
          value: 'usuarioExternoNome',
        },
        { text: 'Descrição', value: 'categoriaDescricao', sortable: false, },
        { text: 'Data de Cadastro', value: 'dataCadastro', sortable: false },
        { text: 'Comissario', value: 'comissario', sortable: false },
        { text: 'Baixado', align: 'end', value: 'isPago', sortable: false, },
        { text: 'Ações', align: 'end', value: 'actions', sortable: false, },
      ],
      loading: false,
      loader: false,
      cortesias: [],
      loadingCortesia: false,
      dialogCriarUsuario: false,
      newCounter: 0,
      rules: {
          email: value => {
              const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
              return pattern.test(value) || "Endereço de email inválido.";
          }
      }

    };
  },

  filters: {
    filtraDH_fromNow(val) {
      if (val) {
        return moment(val).fromNow();
      } else {
        return "Inválido";
      }
    },
    filtraDH(val) {
      if (val) {
        return moment(val).format("DD/MM/YYYY");
      } else {
        return "Inválido";
      }
    },
    filtrarExtenso(val) {
      if (val) {
        return moment(val).format("LLLL");
      } else {
        return "Inválido";
      }
    }
  },


  created(){
    this.getIngressos();
    this.getCortesias();
  },

  methods: {
    handleOpenForm(){
      this.editing = false
      if(this.situacao !== 5){
        return this.$root.showErro('O Ingresso deve estar publicado');
      }
      if(new Date() > this.date){
        return this.$root.showErro('O Evento acabou');
      }
      this.clean()
      this.counter += 1
      this.openForm = true
      
    },

    getIngressos(){
      this.loader = true
      IngressoService.getCategoriasAtivas(this.id)
      .then((response) => {
          this.categorias = response.data.map((c) => 
          ({
              text: c.descricao,
              value: c.id
          }))
      })
      .catch((err) => {
        this.$root.showErro(err.data);
      })
      .finally(() => {
        this.loader = false
      });
    },

    salvar(){
      const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if(!this.usuario  || !this.usuario.match(pattern)){
          return this.$root.showErro('Insira um e-mail válido');
      }
      this.loading = true
      UsuarioExternoService.getByEmail(this.usuario)
      .then((response) => {
          this.usuarioId = response.data.id
          this.salvarCortesia()
      })

      .catch((err) => {
        if(err.status == 400){
          this.dialogCriarUsuario = true
        } else {
          this.$root.showErro(err.data.message);
        }
      })
      .finally(() => {
        this.loading = false
      })
    },

    newUsuarioExterno(){
      let payload = {
        email: this.usuario
      }
      UsuarioExternoService.newUsuarioExterno(payload)
      .then(response => {
        this.usuarioId = response.data.id;
        this.salvarCortesia()
      })
      .catch((err) => {
        this.$root.showErro(err.data);
      })
      .finally(() => {
        this.dialogCriarUsuario = false
      });
    },

    salvarCortesia(){
      this.loading = true
      console.log("quantidade")
      console.log(this.quantidade)
      let payload = {
          produtoDetalheId: this.categoria,
          usuarioExternoId: this.usuarioId,
          quantidade: this.quantidade,
      }
      CortesiaService.salvarCortesia(payload)
      .then((response) => {
          this.$root.showSucesso(response.data);
          this.openForm = false
          this.getCortesias()
      })
      .catch((err) => {
        this.$root.showErro(err.data);
      })
      .finally(() => {
        this.loading = false
      })
    },

    getCortesias(){
      this.loader= true
      CortesiaService.getOne(this.id)
      .then((response) => {
        this.cortesias = []
        this.cortesias = response.data
      })
      .catch((err) => {
        if(err.status == 400){
          this.cortesias = []
        }
        console.log(err)
      })
      .finally(() => {
        this.newCounter += 1
        this.loader = false
      });
    },
    
    handleEdit(item){
      this.editing= true
      this.openForm = true
      this.usuario = item.usuarioExternoEmail
      this.comissarioNome = item.comissarioNome
      this.categoria = item.categoriaId
    },


    handleDelete(item){
      this.dialogExcluirCortesia = true
      this.cortesiaObj = item
    },

    excluirCortesia(){
      this.loader= true
      CortesiaService.delete(this.cortesiaObj.id)
      .then((response) => {
          this.$root.showSucesso(response.data);
      })
      .catch((err) => {
        this.$root.showErro(err.data);
      })
      .finally(() => {
        this.getCortesias()
        this.loader = false
        this.dialogExcluirCortesia = false
      });
    },

    clean(){
      this.usuario = null
      this.categoria = null
      this.comissarioNome = null
    },

    handleClose(){
      this.clean()
      this.$emit('close')
    }
  }

};
</script>

<style>
.w-mid{
  width: 50%
}

.w-1-4{
    width: 25%
}
.scroll-height{

  scroll-behavior: smooth;
}

</style>