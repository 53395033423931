<template>
  <div class="mt-8 d-flex ml-8">
    <div v-if="!showDetalhes" class="d-flex">
      <div v-for="item in list" :key="item.id">
        <card-produto :item="item" :hasActions="true" @showDetalhes="openDetalhes" @cortesia="openCortesia" @resumo="getRelatorio" class="mr-4 mb-4 mx-w"/>
      </div>
    </div>
    <div v-if="showDetalhes">
      <card-detalhe :item="detalhes" @voltar="handleClose" />
    </div>
    <modulo-cortesia 
      v-if="cortesiaDialog"
      :id="produtoId"
      :situacao="5"
      :eventName="eventName"
      :date="dataVencimento"
      :comissario="comissario"
      @close="cortesiaDialog = false"
    />
    <v-dialog
      v-model="resumoDialog"
      max-width="60vw"
      height="auto"
      @click:outside="resumoDialog = false"
      transition="dialog-transition"
    >
     <v-card elevation="2" tile >
      <v-toolbar flat>
        <v-toolbar-title>
          <span class="headline"> Resumo de Vendas  </span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-icon large @click="resumoDialog = false">mdi-close</v-icon>
      </v-toolbar>
      <v-divider />
      <div v-for="(categoria, i) in resumo" :key="i" class="mt-2 pa-4">
        <span class="font-weight-bold text-subtitle-1"> Categoria: </span> 
        <span> {{ categoria.descricao }}</span>
        <div>
          <span class="font-weight-bold text-subtitle-1" > Vendidos: </span>
          <span> {{ categoria.vendido }} de {{ categoria.total }} </span>
        </div>
        <v-divider />
      </div>
     </v-card>
  </v-dialog>
  <v-dialog
    v-model="relatorioDialog"
    hide-overlay
  >
    <v-card>
        <v-toolbar flat>
            <v-toolbar-title>
                <span class="headline"> Relatório de Ingressos </span>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-icon large @click="relatorioDialog = false"> mdi-close </v-icon>
        </v-toolbar>
        <v-divider class="mb-4"/>
        <v-data-table
            :headers="headers"
            :items="relatorioInfo"
            :items-per-page="5"
            class="elevation-1"
        >
        <template v-slot:body="{ items }" ma-5>
        <tbody>
            <tr v-for="item in items" :key="item.usuarioNome">
            <td>{{ item.usuarioNome }}</td>
            <td>{{ item.categoria }}</td>
            <td >  {{ item.isCortesia ? `Cortesia` : `R$ ${asMoney(item.valor)}`}}</td>
            <td> {{ item.dataCompra | filtraDH}}</td>
            </tr>
        </tbody>
        </template>
        </v-data-table>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from "moment"; 
import CardProduto from "@/components/cardWeb.vue";
import CardDetalhe from "@/components/cardDetalhes.vue"
import moduloCortesia from "../produtos/componentes/moduloCortesia.vue";
import { ComissarioService, IngressoService } from "@/services";


export default {
  components: {
    CardProduto,
    CardDetalhe,
    moduloCortesia,
  },

  data() {
    return {
      list: [],
      showDetalhes: false,
      detalhes: {},
      cortesiaDialog: false,
      resumoDialog: false,
      eventName: "",
      dataVencimento: "",
      resumo: {},
      produtoId: "",
      comissario: {},
      relatorioDialog: false,
      headers: [
        { text: "Cliente", value: "usuarioNome" },
        { text: "Categoria", value: "categoria" },
        { text: "Valor", value: "valor" },
        { text: "Data de Compra", value: "dataCompra" },
      ],
      relatorioInfo: [],
    };
  },

  filters: {
        filtraDH(val) {
        if (val) {
            return moment(val).format("DD/MM/YYYY");
        } else {
            return "Ingresso não encerrado";
        }
        }
    },

  created() {
    this.getList();
  },

  methods: {
    getList() {
      ComissarioService.getComissarioItems()
        .then((response) => {
          this.list = response.data;
        })
        .catch((e) => {
          this.$root.showErro(e.data);
        });
    },

    openDetalhes(item){
      this.detalhes = item
      this.showDetalhes = true
    },

    openCortesia(item){
      this.produtoId = item.id
      this.dataVencimento = item.validade
      this.eventName = item.descricao
      this.comissario = { 
        nome: item.comissarioNome,
        email: item.comissarioEmail
      }
      this.cortesiaDialog = true
    },

  getRelatorio(item){
    this.relatorioDialog = true
    console.log(item)
    IngressoService.getRelatorio(item.id)
    .then((response) => {
        this.relatorioInfo = response.data.content
    })
    .catch((error) => {
    if (error.status != 401) {
        this.$root.showErro(error.data);
    }
    })
    .finally(() => {
    this.loaderDialog = false
    });
  }
  },
};
</script>

<style>
.mx-w{
  max-width: 400px !important;
}
</style>